/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'globe': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.62 2.62A8.946 8.946 0 019 0a8.946 8.946 0 019 9 8.946 8.946 0 01-9 9 8.946 8.946 0 01-9-9 8.946 8.946 0 012.62-6.38zm11.704 11.24a1.986 1.986 0 00-.655-.887 1.948 1.948 0 00-1.077-.38h-.887v-2.7a.86.86 0 00-.254-.635.86.86 0 00-.634-.254H5.409V7.187H7.18a.86.86 0 00.634-.254c.17-.17.277-.394.3-.634V4.482H9.89c.48.011.944-.172 1.289-.507.348-.328.54-.789.528-1.268v-.38a7.31 7.31 0 013.254 2.6 7.176 7.176 0 011.268 4.077 7.87 7.87 0 01-.528 2.641 7.064 7.064 0 01-1.377 2.214zm-6.211 2.28a7.139 7.139 0 01-4.5-2.345 7.289 7.289 0 01-1.838-4.8 5.972 5.972 0 01.21-1.606l4.31 4.31v.887c-.01.486.18.955.529 1.294.338.347.804.538 1.289.528v1.732z" _fill="#898B9B"/>'
  }
})
